import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AppConfig } from './app.config';
import { FrameworkModule } from './framework/framework.module';

// import { DemoModule } from './demo/demo.module';
// import { DemoCommonModule } from './demo-common/demo-common.module';

import { TemplateModule } from './template/template.module';
import { TemplateCommonModule } from './template-common/template-common.module';

import { SharedModule } from './shared/shared.module';
import { LoginComponent } from './login/login.component';
import { LandingComponent } from './landing/landing.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
//import { ContainerModule } from './modules/container/container.module'; //sb disable container


import { DashboardDemoComponent } from './modules/container/pages/dashboard/dashboarddemo.component';
import { DocumentSearchComponent } from './document-search/document-search.component';





export function initializeApp(appConfig: AppConfig) {
  const promise = appConfig.load().then(() => {
      if (AppConfig.settings && AppConfig.settings.logging &&
          AppConfig.settings.logging.appInsights) {
          // const config: Microsoft.ApplicationInsights.IConfig = {
          //     instrumentationKey: AppConfig.settings.appInsights.instrumentationKey
          // };
          // AppInsights.downloadAndSetup(config);
      }
  });
  return () => promise;
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LandingComponent,
    PageNotFoundComponent,

    DashboardDemoComponent,

    DocumentSearchComponent
  ],
  imports: [
    BrowserModule,

    FrameworkModule,
    BrowserAnimationsModule,

    //DemoCommonModule,
    // DemoModule,

    TemplateModule,
    TemplateCommonModule,

    SharedModule,
    //ContainerModule, //sb disable container
    AppRoutingModule



  ],
  providers: [
    AppConfig,
    { provide: APP_INITIALIZER,  useFactory: initializeApp, deps: [AppConfig], multi: true }
    //{ provide: UrlSerializer, useClass: LowerCaseUrlSerializer }
],
  bootstrap: [AppComponent]
})
export class AppModule { }
