import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TableModule} from 'primeng/table';
import { SliderModule} from 'primeng/slider';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';

import {MenubarModule} from 'primeng/menubar';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {ScrollPanelModule} from 'primeng/scrollpanel';

import {FileUploadModule} from 'primeng/fileupload';
import { InputSwitchModule } from 'primeng/inputswitch';

import { PanelModule } from 'primeng/panel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChartModule } from 'primeng/chart';
import { MenuModule } from 'primeng/menu';

import { AlertComponent } from './directives/alert/alert.component';

import { HideIfUnauthorizedDirective } from './directives/hide-if-unauthorized/hide-if-unauthorized.directive';
import { DisableIfUnauthorizedDirective } from './directives/disable-if-unauthorized/disable-if-unauthorized.directive';
import { ErrorMessageComponent } from './directives/error-message/error-message.component';
import { DateToStringPipe } from './pipes/date-to-string.pipe';
import { DisplayErrorPipe } from './pipes/display-error.pipe';

import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,

        TableModule,
        SliderModule,
        DropdownModule,
        MultiSelectModule,
        MenubarModule,
        InputTextModule,
        ButtonModule,
        FileUploadModule,
        ScrollPanelModule,
        InputSwitchModule,
        PanelModule,
        CheckboxModule,
        ChartModule,
        MenuModule,

        NgxPageScrollModule,
        NgxPageScrollCoreModule
        

    ],
    declarations: [
        AlertComponent,
        ErrorMessageComponent,
        HideIfUnauthorizedDirective,
        DisableIfUnauthorizedDirective,
        DateToStringPipe,
        DisplayErrorPipe
    ],
    providers: [
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,

        AlertComponent,
        ErrorMessageComponent,
        HideIfUnauthorizedDirective,
        DisableIfUnauthorizedDirective,
        DateToStringPipe,
        DisplayErrorPipe,

        TableModule,
        SliderModule,
        DropdownModule,
        MultiSelectModule,
        MenubarModule,
        InputTextModule,
        ButtonModule,
        FileUploadModule,
        ScrollPanelModule,
        InputSwitchModule,
        PanelModule,
        CheckboxModule,
        ChartModule,
        MenuModule,

        NgxPageScrollModule,
        NgxPageScrollCoreModule

    ]
})
export class SharedModule {
}
