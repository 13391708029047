// sb
// <reference path="../../../../node_modules/@types/adal/index.d.ts" />
import { WebStorageService } from './web-storage.service';
import { Injectable } from '@angular/core';

//sb
// import 'expose-loader?AuthenticationContext!adal-angular/lib/adal.js';

import { LoggingService } from '../logging/logging.service';
import { AppConfig } from '../../app.config';

@Injectable()
export class AuthService {
    public accessToken = '';
    public loginError = '';
    private originalRoute = window.location.pathname;
    //sb
    // private config: adal.Config = {
    //     tenant: AppConfig.settings.aad.tenant,
    //     clientId: AppConfig.settings.aad.clientId,
    //     postLogoutRedirectUri: window.location.origin,
    //     redirectUri: window.location.origin,
    //     navigateToLoginRequestUrl : false,
    //     cacheLocation: 'sessionStorage'
    // };
    // private currentUser: adal.User;
    // private authContext: adal.AuthenticationContext;

    get isUserAuthenticated(): boolean {
        // sb
        // return !!this.currentUser;
        return true;
    }
    get currentUserName(): string {
        //sb 
        // return this.isUserAuthenticated ? this.currentUser.profile.name : '';
        return "test value"
    }
    get currentUserLoginName(): string {
        //sb 
        // return this.isUserAuthenticated ? (this.currentUser.userName !== '' ? this.currentUser.userName.split('@', 1)[0] : '' ) : '';
        return "test value"

    }
    constructor(private loggingService: LoggingService) {
        // sb
        // if (AppConfig.settings.aad.requireAuth) {
            // this.authContext = new AuthenticationContext(this.config);
            // this.authenticate();
        //}
    }

    authenticate() {
        // sb
        // const haveToken = this.authContext.isCallback(window.location.hash);
        // if (haveToken) {
        //     this.originalRoute = WebStorageService.getSessionStorage('originalRoute');
        //     this.authContext.handleWindowCallback();
        // } else {
        //     // Keep track of the url that the user wanted to use before logging in
        //     WebStorageService.setSessionStorage('originalRoute', this.originalRoute);
        // }
        // this.loginError = this.authContext.getLoginError();
        // this.currentUser = this.authContext.getCachedUser();
    }

    getAccessToken() {
        // sb
        // const p = new Promise<string>((resolve, reject) => {
        //     this.authContext.acquireToken(AppConfig.settings.aad.clientId, (error, token) => {
        //         if (error || !token) {
        //             this.loggingService.logError(
        //                 'ADAL error occurred in acquireToken: ' + error);
        //             reject(error);
        //         } else {
        //             resolve(token);
        //         }
        //     });
        // });
        // return p;

        const p = new Promise<string>((resolve) => {
            
                
                    resolve();
               
            
        });
        return p;
    }

    logIn() {
        // sb
        //this.authContext.login();
    }

    logOut() {
        // sb
        //this.authContext.logOut();
    }

    getOriginalRoute() {
        return this.originalRoute;
    }

    clearOriginalRoute() {
        if (this.originalRoute) {
            this.originalRoute = null;
            WebStorageService.removeSessionStorage('originalRoute');
        }
    }
}
