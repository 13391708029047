import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppInjector } from './app/app-injector.service';

//sb
// if (environment.production) {
//   enableProdMode();
// }


if (environment.name !== 'dev') {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule).then((moduleRef) => {
    AppInjector.getInstance().setInjector(moduleRef.injector);
});


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
