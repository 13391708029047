import {
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from '../../framework/services/auth.service';
import { AuthorizationService } from '../../framework/services/authorization.service';
import { Component, ViewChild, ElementRef, OnDestroy, OnInit, HostListener } from '@angular/core';
import { GlobalEventsService } from '../../framework/services/global-events.service';
import { ScrollPanel } from 'primeng/primeng';


@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit, OnDestroy {
  title = 'iTech-entityOne-UI';
  loading = false;
  loadingDataStarted: Subscription;
  loadingDataComplete: Subscription;
  routingComplete: Subscription;
  lastItemClicked: EventTarget;


  //new
  darkTheme = false;

  menuMode = 'static';

  theme = 'blue-light';

  selectedColor = 'blue';

  topbarMenuActive: boolean;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  layoutMenuScroller: HTMLDivElement;

  menuClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: any;

  resetMenu: boolean;

  menuHoverActive: boolean;

  configClick: boolean;

  configActive: boolean;

  @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ScrollPanel;
  //new end

  @ViewChild('loadingGif', { static: false }) loadingElement: ElementRef;

  //new start
  ngAfterViewInit() {
      setTimeout(() => { this.layoutMenuScrollerViewChild.moveBar(); }, 100);
  }
  //new end


  // Keep track of the last thing clicked
  @HostListener('click', ['$event'])
  onclickHander(event: Event) {
      if (event.target) {
          this.lastItemClicked = event.target;
          return true;
      }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {

      // No warning popup if logging out
      if (window.location.href.endsWith('logout')) {
          return;
      }

      // No warning popup if opening mail link
      if (this.lastItemClicked && (<HTMLLinkElement>this.lastItemClicked).href &&
          (<HTMLLinkElement>this.lastItemClicked).href.startsWith('mailto:')) {
          return;
      }
      return false;
  }

  constructor(
      private globalEventsService: GlobalEventsService,
      private router: Router,
      private authorizationService: AuthorizationService,
      private authService: AuthService
  ) {
  }

  ngOnInit() {
      this.router.events.subscribe((event: RouterEvent) => {
          this.handleNavigationEvent(event);
      });

      this.loadingDataStarted = this.globalEventsService.loadingDataStarted.subscribe(() => {
          this.showSpinner();
      });

      this.loadingDataComplete = this.globalEventsService.loadingDataComplete.subscribe(() => {
          if (!this.globalEventsService.isBusyRouting) {
              this.hideSpinner();
          }
      });

      this.routingComplete = this.globalEventsService.routingComplete.subscribe(() => {
          this.authService.clearOriginalRoute();
          this.hideSpinner();
      });
  }

  isNotAuthorizedMessageVisible() {
      if (this.loading) {
          return false;
      }
      if (this.authService.isUserAuthenticated) {
          return !this.authorizationService.hasPermission('VIEW');
      } else {
          return false;
      }
  }

  notAuthorizedMessage() {
      return `User "${this.authService.currentUserLoginName}" is not authorized to access this application.`;
  }

  private handleNavigationEvent(event: RouterEvent) {
      if (event instanceof NavigationStart) {
          this.showSpinner();
      } else if (event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError) {
          if (event instanceof NavigationEnd) {
              window.scrollTo(0, 0);
          }
          this.hideSpinner();
      }
  }

  private showSpinner() {
      this.loading = true;
  }

  private hideSpinner() {
      this.loading = false;
  }

  ngOnDestroy() {
      if (this.loadingDataStarted) {
          this.loadingDataStarted.unsubscribe();
      }
      if (this.loadingDataComplete) {
          this.loadingDataComplete.unsubscribe();
      }
      if (this.routingComplete) {
          this.routingComplete.unsubscribe();
      }
  }

  //new start
  changeTheme(theme) {
      this.selectedColor = theme;
      theme = this.selectedColor + (this.darkTheme ? '-dark' : '-light');
      this.changeStyleSheetsColor('theme-css', 'theme-' + theme + '.css');
      this.changeStyleSheetsColor('layout-css', 'layout-' + theme + '.css');
      this.theme = theme;

      if (theme.indexOf('dark') !== -1) {
        this.darkTheme = true;
      } else {
        this.darkTheme = false;
      }
  }

  changeDarkOrLight(isDark: boolean) {
      if (this.darkTheme !== isDark) {
          this.darkTheme = isDark;
          this.changeTheme(this.selectedColor);
      }
  }


  changeStyleSheetsColor(id, value) {
      const element = document.getElementById(id);
      const urlTokens = element.getAttribute('href').split('/');
      urlTokens[urlTokens.length - 1] = value;

      const newURL = urlTokens.join('/');

      this.replaceLink(element, newURL);
  }

  replaceLink(linkElement, href) {
      const id = linkElement.getAttribute('id');
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute('href', href);
      cloneLinkElement.setAttribute('id', id + '-clone');

      linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

      cloneLinkElement.addEventListener('load', () => {
          linkElement.remove();
          cloneLinkElement.setAttribute('id', id);
      });
  }

  onLayoutClick() {
      if (!this.topbarItemClick) {
          this.activeTopbarItem = null;
          this.topbarMenuActive = false;
      }

      if (!this.menuClick) {
          if (this.isHorizontal() || this.isSlim()) {
              this.resetMenu = true;
          }

          if (this.overlayMenuActive || this.staticMenuMobileActive) {
              this.hideOverlayMenu();
          }

          this.menuHoverActive = false;
      }

      if (this.configActive && !this.configClick) {
          this.configActive = false;
      }

      this.configClick = false;
      this.topbarItemClick = false;
      this.menuClick = false;
  }

  onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarMenuActive = false;

      if (this.isOverlay()) {
          this.overlayMenuActive = !this.overlayMenuActive;
      }
      if (this.isDesktop()) {
          this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
          this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }

      event.preventDefault();
  }

  onMenuClick($event) {
      this.menuClick = true;
      this.resetMenu = false;

      if (!this.isHorizontal()) {
          setTimeout(() => {this.layoutMenuScrollerViewChild.moveBar(); }, 500);
      }
  }

  onTopbarMenuButtonClick(event) {
      this.topbarItemClick = true;
      this.topbarMenuActive = !this.topbarMenuActive;

      this.hideOverlayMenu();

      event.preventDefault();
  }

  onTopbarItemClick(event, item) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === item) {
          this.activeTopbarItem = null;
      } else {
          this.activeTopbarItem = item;
      }

      event.preventDefault();
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  onConfigClick(event) {
      this.configClick = true;
  }

  isHorizontal() {
      return this.menuMode === 'horizontal';
  }

  isSlim() {
      return this.menuMode === 'slim';
  }

  isOverlay() {
      return this.menuMode === 'overlay';
  }

  isStatic() {
      return this.menuMode === 'static';
  }

  isMobile() {
      return window.innerWidth < 1025;
  }

  isDesktop() {
      return window.innerWidth > 1024;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  hideOverlayMenu() {
      this.overlayMenuActive = false;
      this.staticMenuMobileActive = false;
  }
  //new end
}
