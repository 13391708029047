import { Component } from '@angular/core';
import { ContainerComponent } from 'src/app/modules/container/container.component';

@Component({
    selector: 'app-topbar',
    templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {

    constructor(public app: ContainerComponent) { }
}
