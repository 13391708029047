import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../../../framework/components/base-component';
//import { IDataTableColumn } from '../../../shared/directives/data-table/data-table-models';
import { IEntitySearchResult } from '../../../template-common/models/entity-search-result.models';
import { UserSessionService } from '../../../template-common/services/user-session.service';
import { SelectItem, FilterUtils } from 'primeng/primeng';

@Component({
    selector: 'la-search-results',
    templateUrl: './search-results.component.html',
    styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent extends BaseComponent implements OnInit, OnDestroy {

    searchResultList: Array<IEntitySearchResult> = [];

    searchResultColumns: Array<any>  = [
        { name: 'id', header: 'Id', sortable: true, dataType: 'url', width: '9%', link: 'entities/:id' },
        { name: 'forename', header: 'Forename', sortable: true, dataType: 'url', width: '33%', link: 'entities/:id' },
        { name: 'surname', header: 'Surname', sortable: true, dataType: 'url', width: '33%', link: 'entities/:id' },
        { name: 'name', header: 'Name', sortable: true, dataType: 'url', width: '33%', link: 'entities/:id' },
        { name: 'type', header: 'Entity Type', sortable: true, width: '9%' }//,
        // { name: 'accountStatusDisplay', header: 'Status', sortable: true, width: '8%', excludeFromGlobalFilter: true }
    ];

    errorsFromServer: Array<string> = [];
    names: SelectItem[];

    types: SelectItem[];
    idFilter: number;
    yearTimeout: any;


    constructor(private route: ActivatedRoute,
        private userSessionService: UserSessionService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.eventSubscriptions.push(this.route.data
            .subscribe((data: { searchResults: Array<IEntitySearchResult> }) => {
                this.errorsFromServer = [];
                if (this.userSessionService.navigationError) {
                    this.errorsFromServer =
                        this.errorUtilitiesService.parseNavigationError(this.userSessionService.navigationError);
                    this.userSessionService.navigationError = null;
                }
                if (data.searchResults) {
                    this.searchResultList = data.searchResults;
                } else {
                    this.searchResultList = [];
                }
            }));

        this.names = [
            { label: 'All Brands', value: null },
            { label: 'Audi', value: 'Audi' },
            { label: 'BMW', value: 'BMW' },
            { label: 'Fiat', value: 'Fiat' },
            { label: 'Honda', value: 'Honda' },
            { label: 'Jaguar', value: 'Jaguar' },
            { label: 'Mercedes', value: 'Mercedes' },
            { label: 'Renault', value: 'Renault' },
            { label: 'VW', value: 'VW' },
            { label: 'Volvo', value: 'Volvo' }
        ];

        this.types = [
            { label: 'Male', value: 'Male' },
            { label: 'Female', value: 'Female' }
        ];


    }

    onIdChange(event, dt) {
        if (this.yearTimeout) {
            clearTimeout(this.yearTimeout);
        }

        this.yearTimeout = setTimeout(() => {
            dt.filter(event.value, 'id', 'gt');
        }, 250);
    }
}
