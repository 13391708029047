import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { IEntitySearchResult } from '../../../template-common/models/entity-search-result.models';
import { TemplateResolver } from '../../../template-common/services/template-resolver.service';
import { SearchService } from '../../../template-common/services/search.service';

@Injectable()
export class SearchResultsResolver extends TemplateResolver
                                   implements Resolve<Array<IEntitySearchResult>> {

    constructor(private searchService: SearchService) {
        super();
    }

    resolve(route: ActivatedRouteSnapshot) {
        super.resolve(route);
        return this.searchService.getEntities();
    }
}
