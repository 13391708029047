import { Injectable } from '@angular/core';
import { IEntitySearchResult } from '../models/entity-search-result.models';
import { TemplateCommonDataService } from './template-common-data.service';

@Injectable()
export class SearchDataService extends TemplateCommonDataService {

    getEntitySearchResults() {
        const endpoint = `${this.apiServer.rules}entities/`;
        return this.get<Array<IEntitySearchResult>>(endpoint);
    }
}
