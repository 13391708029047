import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
//import { HeaderBarComponent } from './directives/header-bar/header-bar.component';
import { SearchDataService } from './services/search-data.service';
import { SearchService } from './services/search.service';
import { UserSessionService } from './services/user-session.service';
import { ComponentHeaderComponent } from './directives/component-header/component-header.component';
import { TemplateCommonDataService } from './services/template-common-data.service';
import { SharedModule } from '../shared/shared.module';

import { AppMenuComponent, AppSubMenuComponent } from '../framework-ui/directives/menu/app.menu.component';
import { AppTopBarComponent } from '../framework-ui/directives/top-bar/app.topbar.component';
import { AppFooterComponent } from '../framework-ui/directives/footer/app.footer.component';
import { AppBreadcrumbComponent } from '../framework-ui/directives/breadcrumb/app.breadcrumb.component';

import { BreadcrumbService } from '../framework-ui/services/breadcrumb.service';

// import { TransactionDataService } from './services/transaction-data.service';
// import { TransactionService } from './services/transaction.service';
// import { TransactionEntityDataService } from './services/transaction-entity-data.service';
//import { TransactionConfirmDialogComponent } from './directives/transaction-confirm-dialog/transaction-confirm-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        //HeaderBarComponent,
        //TransactionConfirmDialogComponent,
        ComponentHeaderComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent
    ],
    providers: [
        UserSessionService,
        TemplateCommonDataService,
        // TransactionEntityDataService,
        // TransactionDataService,
        // TransactionService,
        SearchDataService,
        SearchService,
        BreadcrumbService

    ],
    exports: [
        
        //HeaderBarComponent,
        //TransactionConfirmDialogComponent,
        ComponentHeaderComponent,  
        AppMenuComponent,
        AppSubMenuComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent
    ]
})
export class TemplateCommonModule {
}
