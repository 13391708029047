import { Injectable } from '@angular/core';
import { IEntitySearchResult } from '../models/entity-search-result.models';
import { SearchDataService } from './search-data.service';
// import { IChild1Entity } from '../../demo/accounts/shared/models/child1-entity.models';

@Injectable()
export class SearchService {

    private entities: Array<IEntitySearchResult> = [];

    constructor(private searchDataService: SearchDataService) {
        this.entities = [];
    }

    getEntities() {
        return this._getEntities();
    }

    // accountUpdated(child1: IChild1Entity) {
    //     this.updateList(this.accounts, child1);
    // }

    // private updateList(accountList: IAccountSearchResult[], child1: IChild1Entity) {
    //     // Find the saved account in the search results list
    //     const index = accountList.findIndex(p => {
    //         return p.accountCode === child1.accountCode;
    //     });

    //     // If found, update the name if that was changed
    //     if (index !== -1) {
    //         accountList[index].accountName = child1.name1;
    //     }
    // }

    //sb here
    private _getEntities() {
        const promise = new Promise<IEntitySearchResult[]>((resolve, reject) => {
            if (this.entities.length > 0) {
                resolve(this.entities);
            } else {
                // this.searchDataService.getEntitySearchResults()
                //     .then(results => {
                //         results.forEach(item => {
                //             // item.accountStatusDisplay = item.accountStatus === 1 ? 'Active' : 'Inactive';
                //         });
                //         this.entities = results;
                //         resolve(results);
                //     }).catch((e) => {
                //         reject(e);
                //     });

                const searchResults = [{
                    id: "1",
                    forename: 'ABC123',
                    surname: 'Test Entity 1',
                    name: 'ABC123' + ' ' + 'Test Entity 1',
                    type: "ijih"
                },
                {
                    id: "2",
                    forename: 'DEF123',
                    surname: 'Test Entity 2',
                    name: 'DEF123' + ' ' + 'Test Entity 2',
                    type: "ijih"
                }
                ];

                this.entities = searchResults;
                resolve(searchResults);
            }
        });
        return promise;
    }
}
